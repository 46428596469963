@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: -webkit-fill-available;
}

#root {
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media print {
  body * {
    display: none;
  }
  #qrCodeToPrint, #qrCodeToPrint * {
    display: block;
  }
  #qrCodeToPrint {
    position: fixed;
    left: 50%;
    top: 0;
    width: 100%;
    height: auto;
  }
}

.react-datepicker {
  border: none !important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__current-month {
  text-transform: capitalize;
  font-size: 1rem !important;
}

.react-datepicker__day-names {
  text-transform: capitalize;
  font-size: 1rem;
  display: flex;
  justify-content: space-around;
}

.react-datepicker__week {
  display: flex;
  justify-content: space-around;
}

.react-datepicker__day {
  font-size: 1.3rem;
  text-align: center;
}

.react-datepicker__day--selected span {
  color: #ffffff !important;
}

.react-datepicker__day .day-disabled {
  color: #64748b45;
}

.react-datepicker__day .day-enabled {
  color: #22c55e;
}