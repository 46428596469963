.calendar {
  display: grid;
  grid-template-columns: repeat(8, 1fr); /* 7 days a week */
  grid-auto-rows: 60px; /* Height of each hour row */
  gap: 1px; /* Space between grid items */
}

.time-slot {
  text-align: center;
  position: relative;
}

.hour {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.hour .hour-text {
  width: 100%;
  text-align: center;
  margin-top: -15px;
  z-index: 10;
}

.day-header {
  text-align: center;
  grid-column: span 1; /* Each header spans 1 column */
}

.appointment-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.appointment {
  position: absolute;
  font-size: 10px; /* Start with a small font size and adjust as needed */
  text-align: left;
  align-items: flex-start;
  word-wrap: break-word; /* Allows the text to wrap within the container */
  overflow-wrap: break-word; /* Alternative to word-wrap, use as needed */
  width: 100%; /* Ensure the text can expand to the full width of its container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  overflow: hidden;
  color: black;
  font-weight: bold;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
